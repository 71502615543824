import { render, staticRenderFns } from "./AccountInvitationDialog.vue?vue&type=template&id=3fd76fd4&lang=pug&"
import script from "./AccountInvitationDialog.vue?vue&type=script&lang=ts&"
export * from "./AccountInvitationDialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports