
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { Server } from '@/models/server'
import { ScheduledJob } from '@/models/scheduled-job'
import { BackupJobConfig } from '@/models/backup-job'
import { LogFileOptions } from '@/models/log-file-options'
import NxaAlert from '@/components/NxaAlert.vue'
import moment from 'moment'
import VueCronEditorBuefy from 'vue-cron-editor-buefy'

@Component({
  components: {
    NxaAlert,
    VueCronEditorBuefy
  }
})
export default class EditBackupSchedule extends Vue {
    private readonly additionalLanguageCron = require('@/i18n/cron-editor-de.json')
    private readonly cronEditorTabs = ['hourly', 'daily', 'weekly', 'monthly']

    private isLoading = false
    private isSubmitted = false
    private valid = false

    @Prop()
    private server: Server

    @Prop()
    private preventRedirect: boolean

    private localServer: Server = null

    private showDialog = false

    private LogFileOptionsEnum = LogFileOptions
    private randomTime: string
    private randomDayName: string
    private randomDayOfMonth: number
    private logFileLevel = LogFileOptions.None
    // default pattern for 'every hour at minute 0', otherwise component doesn't work correctly upon initScheduleInput.
    private intervalPattern = '0 */1 * * *'
    private isActive = true
    private jobId = 0
    private successNotification = true
    private failureNotification = true
    private backupKeepAmt = 7
    private includeXlogicFiles = true
    private includeNxaVersionOutput = true
    private randomDayIdx: number
    private selectedLanguage: string
    private cronErrMsg = ''

    @Ref('form') private readonly form!: HTMLFormElement

    @Watch('showDialog')
    private handleDialogReload() {
      if (this.showDialog) {
        this.initScheduleInput()
      }
    }

    public edit() {
      this.localServer = Object.assign({}, this.server)
      this.showDialog = true
    }

    private getRandomInt(maxValue: number): number {
      return Math.floor(Math.random() * maxValue)
    }

    private async initScheduleInput() {
      this.isLoading = true
      const data = await this.$backend.get(`/servers/${this.server.id}/configured-backup-schedule`) as ScheduledJob[]

      if (data.length !== 0) {
        const backupSchedule = data[0]
        const backupConfig = backupSchedule.jobConfig as BackupJobConfig

        this.logFileLevel = backupConfig.logFileLevel
        this.intervalPattern = this.cronToLocal(backupSchedule.cronExpression)
        this.jobId = backupSchedule.id
        this.isActive = backupSchedule.isActive
        this.successNotification = backupConfig.successNotification
        this.failureNotification = backupConfig.failureNotification
        this.backupKeepAmt = backupConfig.keepAmount
        this.logFileLevel = backupConfig.logFileLevel
        this.includeXlogicFiles = backupConfig.xlogic
        this.includeNxaVersionOutput = backupConfig.nxaVersions
      }

      const hour = this.getRandomInt(23)
      const minute = this.getRandomInt(59)
      const nowMoment = moment(moment.now())

      this.randomTime = moment(`${hour}:${minute}`, 'HH:mm').format('HH:mm')
      const randomDayOfWeek = this.getRandomInt(6)

      const randDayHelper = nowMoment.clone().locale(navigator.language).day(randomDayOfWeek)
      this.randomDayIdx = randDayHelper.day()
      this.randomDayName = randDayHelper.format('dddd')
      const lastDayOfMonth = Number(nowMoment.clone().endOf('month').format('DD'))
      this.randomDayOfMonth = this.getRandomInt(lastDayOfMonth - 1) + 1

      this.isLoading = false
    }

    private cronToLocal(expression: string): string {
      return this.convertCronTimezone(expression, moment().utcOffset())
    }

    private localCronToUtc(expression: string): string {
      return this.convertCronTimezone(expression, -moment().utcOffset())
    }

    private convertCronTimezone(expression: string, offset: number) {
      const fields = expression.split(' ')
      let minutes = Number(fields[0])
      if (Number.isInteger(minutes)) {
        minutes += offset % 60
        while (minutes >= 60) {
          minutes -= 60
        }
        while (minutes < 0) {
          minutes += 60
        }
        fields[0] = minutes.toString()
      }
      let hours = Number(fields[1])
      if (Number.isInteger(hours)) {
        hours += Math.floor(offset / 60)
        while (hours >= 24) {
          hours -= 24
        }
        while (hours < 0) {
          hours += 24
        }
        fields[1] = hours.toString()
      }
      return fields.join(' ')
    }

    private async created() {
      // de-AT / de-DE => de (defaulting to 'en')
      this.selectedLanguage = navigator.language.split('-')[0] || 'en'
    }

    private async updateServer() {
      if (!this.form.validate()) {
        return
      }

      if (!this.intervalPattern || this.cronErrMsg !== '') {
        return
      }

      const sendData = new ScheduledJob()
      sendData.id = this.jobId
      sendData.isActive = this.isActive
      sendData.cronExpression = this.localCronToUtc(this.intervalPattern)
      const backupConf = new BackupJobConfig()
      backupConf.successNotification = this.successNotification
      backupConf.failureNotification = this.failureNotification
      backupConf.keepAmount = this.backupKeepAmt
      backupConf.logFileLevel = this.logFileLevel
      backupConf.xlogic = this.includeXlogicFiles
      backupConf.nxaVersions = this.includeNxaVersionOutput
      backupConf.workspaceName = this.server.workspaceName
      sendData.jobConfig = backupConf
      sendData.serverId = this.localServer.id

      this.$backend.post(`/servers/${this.server.id}/schedule-backup`, sendData)

      this.isSubmitted = true

      const server = await this.$backend.put('/servers/', this.localServer, Server)
      if (!this.preventRedirect) {
        this.$router.push(
          `/${this.localServer.orgSlug}/servers/${server.slug}`
        )
      }

      this.server.name = server.name
      this.showDialog = false
      this.isSubmitted = false
    }

    private parseRandomTime(): string[] {
      const hours = moment(this.randomTime, 'HH:mm').format('H')
      const mins = moment(this.randomTime, 'HH:mm').format('m')
      return [hours, mins]
    }

    public handleDailyInterval(): void {
      const [hours, mins] = this.parseRandomTime()
      this.intervalPattern = `${mins} ${hours} */1 * *`
    }

    public handleWeeklyInterval(): void {
      const [hours, mins] = this.parseRandomTime()
      this.intervalPattern = `${mins} ${hours} * * ${this.randomDayIdx}`
    }

    public handleMonthlyInterval(): void {
      const [hours, mins] = this.parseRandomTime()
      this.intervalPattern = `${mins} ${hours} ${this.randomDayOfMonth} */1 *`
    }

    @Watch('intervalPattern')
    private validateCronInput(): boolean {
      if (this.intervalPattern == null) {
        this.cronErrMsg = this.$t('backup.schedule.error.general') as string
        return false
      }

      // disabled, because mins/weekdays are already checked and thus correct, don't need to check them again.
      // eslint-disable-next-line
      const [mins, hrs, days, mons, weekdays] = this.intervalPattern.split(' ')

      if (hrs !== '*') {
        let hrsNum = 0
        if (hrs.includes('/')) {
          hrsNum = Number(hrs.split('/')[1])
        } else {
          hrsNum = Number(hrs)
        }

        if (hrsNum < 0 || hrsNum >= 24) {
          this.cronErrMsg = this.$t('backup.schedule.error.hours') as string
          return false
        }
      }

      if (days !== '*') {
        const daysNum = Number(days.split('/')[1])

        if (daysNum > 31) {
          this.cronErrMsg = this.$t('backup.schedule.error.days') as string
          return false
        }
      }

      if (mons !== '*') {
        const monsNum = Number(mons.split('/')[1])

        if (monsNum > 12) {
          this.cronErrMsg = this.$t('backup.schedule.error.months') as string
          return false
        }
      }

      this.cronErrMsg = ''
      return true
    }
}
