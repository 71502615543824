
import { InvitationResult } from '@/models/invitation-result'
import { Component, Vue } from 'vue-property-decorator'
import { Invitation } from '@/models/invitation'
import { getUser } from '@/services/Environment'
import { Role } from '@/models/role'
import { Organization } from '@/models/organization'

@Component({})
export default class AccountInvitationDialog extends Vue {
  private isLoading = false

  private invitations: Invitation[] = []

  private showDialog = false

  private orgId: number = null

  private allRoles: Role[]
  private defaultRole: Role

  public async show(organization: Organization = null) {
    this.orgId = organization?.id

    if (!this.orgId) {
      this.orgId = getUser().organization.id
    }

    this.allRoles = await this.$backend.list('/roles/assignable-roles', Role)

    this.defaultRole = this.allRoles.find((role) => {
      return role.name === 'org_member'
    })

    this.invitations = []
    for (let i = 0; i < 3; i++) {
      this.addEmptyRow()
    }
    this.showDialog = true
  }

  private addEmptyRow() {
    this.invitations.push(Invitation.blank(this.defaultRole, this.orgId))
  }

  private async invitePersons() {
    this.isLoading = true
    // Remove empty rows.
    this.invitations = this.invitations.filter((el) => {
      return el.email != null && el.email.length > 0
    })

    let ok = true

    for (const inv of this.invitations) {
      for (const otherInv of this.invitations) {
        if (inv.email === otherInv.email && inv !== otherInv) {
          inv.errorString = this.$t('error.invite.address_unique').toString()
          ok = false
        } else {
          inv.errorString = null
        }
      }
    }
    if (!ok) {
      return
    }

    const invitationsToSend = this.invitations.filter((el) => !el.ok)
    const results = await this.$backend.postList('/accounts/invite', invitationsToSend, InvitationResult)

    for (const result of results) {
      for (const inv of this.invitations) {
        if (inv.email === result.email) {
          if (result.result === 'ok') {
            inv.ok = true
          } else if (result.result === 'alreadyUsed') {
            inv.errorString = this.$t('error.invite.in_use_or_pending').toString()
            ok = false
          } else if (result.result === 'emailNotValid') {
            inv.errorString = this.$t('error.invite.invalid').toString()
            ok = false
          } else {
            inv.errorString = this.$t('error.internal').toString()
            ok = false
          }
        }
      }
    }
    if (ok) {
      setTimeout(() => {
        this.showDialog = false
      }, 1000)
    }
    this.isLoading = false
  }

  private deleteRow(index) {
    this.invitations.splice(index, 1)
  }

  private addressChanged(index) {
    this.invitations[index].ok = false
    this.invitations[index].errorString = null
  }
}
